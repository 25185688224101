@forward '../node_modules/@amzn/katal-components/styles.css';
@use '../node_modules/@amzn/katal-flo-theme-variables/variables';

@use './styles/settings';
@forward './styles/animations';
@forward './styles/overrides';
@forward './styles/utilities';
@forward './styles/global';

@forward './styles/account-details';
@forward './styles/account-header';
@forward './styles/account-settings';
@forward './styles/account-switcher';
@forward './styles/application-details';
@forward './styles/application-list';
@forward './styles/aws-account-picker';
@forward './styles/badge';
@forward './styles/breadcrumb-nav';
@forward './styles/button-wrapper';
@forward './styles/chart-grid';
@forward './styles/checkbox-input';
@forward './styles/chunk-loading-spinner';
@forward './styles/cli-onboarding-token';
@forward './styles/collapsing-text';
@forward './styles/copy-to-clipboard-button';
@forward './styles/community-section';
@forward './styles/content-container';
@forward './styles/dashboard-container';
@forward './styles/date-range-picker';
@forward './styles/details-display';
@forward './styles/development-section';
@forward './styles/dictionary.scss';
@forward './styles/drop-down';
@forward './styles/endpoint-info';
@forward './styles/feedback-tab.scss';
@forward './styles/filter-form';
@forward './styles/forms';
@forward './styles/form-step-layout';
@forward './styles/form-stepper';
@forward './styles/form-callout-box';
@forward './styles/form-stack';
@forward './styles/help-link';
@forward './styles/hero-section';
@forward './styles/homepage-footer';
@forward './styles/icon-input';
@forward './styles/item-list';
@forward './styles/job-detail-pane';
@forward './styles/job-visualization-node';
@forward './styles/json-viewer';
@forward './styles/key-value-pair-input';
@forward './styles/link';
@forward './styles/list';
@forward './styles/log-view';
@forward './styles/manual-deployment-form';
@forward './styles/metrics-chart';
@forward './styles/model-detail-list';
@forward './styles/model-header';
@forward './styles/model-detail-header';
@forward './styles/training-jobs';
@forward './styles/metrics-graph';
@forward './styles/metrics-table';
@forward './styles/multiple-input-field';
@forward './styles/multiple-key-value-input-field';
@forward './styles/name-availability-checker';
@forward './styles/new-account-setup-form';
@forward './styles/new-application';
@forward './styles/new-model-version-details';
@forward './styles/notice';
@forward './styles/markdown-editor';
@forward './styles/modal-form';
@forward './styles/model-preview';
@forward './styles/model-registry-header';
@forward './styles/model-version-details';
@forward './styles/model-version-event-list';
@forward './styles/model-version-event-details';
@forward './styles/operator-flow-details';
@forward './styles/operator-flow-job-visualization';
@forward './styles/operator-flow-visualization-container';
@forward './styles/points-section';
@forward './styles/profile-details';
@forward './styles/recent-accounts';
@forward './styles/redirect-alert';
@forward './styles/retry-menu';
@forward './styles/content-layout';
@forward './styles/sagemaker-pipeline-graph';
@forward './styles/site-container';
@forward './styles/site-navigation';
@forward './styles/site-footer';
@forward './styles/slide-out-panel';
@forward './styles/status';
@forward './styles/status-label';
@forward './styles/status-message';
@forward './styles/subnav-menu';
@forward './styles/data-table';
@forward './styles/template-url-generator';
@forward './styles/tabs';
@forward './styles/tag-input';
@forward './styles/toast';
@forward './styles/tooltip';
@forward './styles/topic-subscription-table';
@forward './styles/type';
@forward './styles/user-script-editor';
@forward './styles/user-script-modal';
@forward './styles/variable-list';
@forward './styles/violations-table';
@forward './styles/workflow-section';

@use '../node_modules/tailwindcss';

// override tailwind rules for cloudscape
table {
  border-collapse: separate;
}

:root {
  --kat-input-background: white;
}
